import { useSuspenseQuery } from "@tanstack/react-query";
import axios from "axios";

import { APIRoute } from "routes";
import { OrganizationFeatureFlags } from "types/feature_flags";
import { localStorageQueryClient, STALE_TIME_RESOURCES } from "utils/query_client";

const fallbackFlags: OrganizationFeatureFlags = {
    aiControlDescriptionCompletion: false,
    controlTesting: false,
    controlTriggering: false,
};
const FEATURE_FLAGS_QUERY_KEY = "feature-flags";
export function useFeatureFlagsQuery() {
    return useSuspenseQuery({
        queryKey: [FEATURE_FLAGS_QUERY_KEY],
        queryFn: async () => {
            const isGlobal = window.location.hostname.split(".")[0] === "global";
            if (isGlobal) {
                return fallbackFlags;
            }
            const data = await axios.get<OrganizationFeatureFlags>(APIRoute.FEATURE_FLAGS);
            return data.data;
        },
        staleTime: STALE_TIME_RESOURCES,
    }, localStorageQueryClient);
}
