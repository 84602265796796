import React from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Button, MenuProps, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { motion } from "motion/react";

import { AddFilterButton } from "./addFilterButton";
import theme from "./theme.module.scss";

export interface FilterBarRowProps {
    isLastFilter: boolean,
    children: React.ReactNode,
    addLabel?: string,
    addFilterItems: MenuProps["items"],
    onFilterRemoveClick?: () => void,
    columnOptions: DefaultOptionType[],
    selectedColumnOption: string,
    onColumnOptionChange: (value: string) => void,
}

export function FilterBarRow({
    children,
    isLastFilter,
    addLabel,
    addFilterItems,
    onFilterRemoveClick,
    columnOptions,
    selectedColumnOption,
    onColumnOptionChange,
}: FilterBarRowProps) {

    const hasRemainingFilters = addFilterItems?.length ? addFilterItems.length > 0 : false;
    return (
        <motion.div
            initial={{ opacity: 0, y: -30, height: 0 }}
            animate={{ opacity: 1, y: 0, height: "auto" }}
            exit={{ opacity: 0, y: 30, height: 0 }}
            transition={{ duration: 0.2 }}
        >
            <div className={theme.filterBarRow}>
                <div>
                    <Select
                        className={theme.selectFilter}

                        options={columnOptions}
                        value={selectedColumnOption}
                        onChange={onColumnOptionChange}
                    />
                </div>

                <div className={theme.filterComponent}>
                    {children}

                    <Button
                        type="link"
                        onClick={onFilterRemoveClick}
                    >
                        <CloseOutlined />
                    </Button>
                    {isLastFilter && hasRemainingFilters && (
                        <AddFilterButton
                            items={addFilterItems}
                            addLabel={addLabel}
                        />
                    )}
                </div>
            </div>
        </motion.div>
    );
}
