import React from "react";

import theme from "./theme.module.scss";

interface EmptyTableBoxProps {
    children?: React.ReactNode,
}

/**
 * Component to use instead of e.g. table rows to display a centered component, such as an empty table message.
 */
export function EmptyTableBox({
    children,
}: EmptyTableBoxProps) {
    return (
        <div className={theme.emptyTableBox}>
            {children}
        </div>
    );
}
