import React from "react";

import classNames from "classnames";

import theme from "./theme.module.scss";

interface RowProps {
    selected?: boolean,
    children: React.ReactNode,
}
/**
* Row component for use within other table components
*/
export function Row({
    selected,
    children,
}: RowProps) {
    return (
        <tr className={classNames(selected && theme.selected, theme.row)}>
            {children}
        </tr>
    );
}
