import React, { MutableRefObject, RefObject, forwardRef, useRef } from "react";

import { Layout } from "antd";
import classNames from "classnames";

import { ActionMenu, ActionMenuProps } from "components/actionMenu";
import { LeftSideMenu, LeftSideMenuProps } from "components/leftSideMenu";
import { Tabs, TabsProps } from "components/tabs";
import { assertNever } from "utils/obj";
import { useOrgCookie } from "utils/orgCookie";

import { Anchor, Anchors } from "./anchors";
import { ApplicationHeader, ApplicationHeaderProps } from "./applicationHeader";
import { GlobalApplicationHeader, GlobalApplicationHeaderProps } from "./globalApplicationHeader";
import { Steps, StepsProps } from "./steps";
import theme from "./theme.module.scss";

export type TabsPropsTyped = { type: "Tabs" } & TabsProps;
export type StepsPropsTyped = { type: "Steps" } & StepsProps;
export interface AnchorsPropsTyped {
    type: "Anchors",
    anchors: Anchor[],
    contentRef?: React.MutableRefObject<HTMLDivElement>,
}
export type NavigationData = TabsPropsTyped | StepsPropsTyped | AnchorsPropsTyped;

interface NavigationProps {
    data: NavigationData,
    appBarContentRef: React.MutableRefObject<HTMLDivElement | null>,
}
const Navigation = ({
    data,
    appBarContentRef,
}: NavigationProps) => {
    switch (data.type) {
        case "Steps":
            return <Steps
                currentStepId={data.currentStepId}
                stepSpecs={data.stepSpecs}
                limitedWidth={data.limitedWidth}
            />;
        case "Tabs":
            return <Tabs
                currentTabId={data.currentTabId}
                tabSpecs={data.tabSpecs}
                className={data.className}
            />;
        case "Anchors":
            return <Anchors
                anchors={data.anchors}
                contentRef={data.contentRef ?? appBarContentRef}
            />;
        default:
            assertNever(data);
    }
};

// Avoid components like this in the future:
// you should declare as much as possible via JSX composition instead of complex prop data structures
export type MenuProps = Omit<LeftSideMenuProps, "id" | "children">;
export type MenuPropsTyped = { type: "Menu" } & MenuProps;
export type ApplicationBarNavigation = TabsPropsTyped | StepsPropsTyped | MenuPropsTyped | AnchorsPropsTyped;

export type GlobalApplicationBarProps = (ApplicationHeaderProps | GlobalApplicationHeaderProps) & {
    navigation?: ApplicationBarNavigation,
    actions?: ActionMenuProps,
    contentClassName?: string,
    children?: React.ReactNode,
    leftSideMenuCollapsed?: boolean,
    onLeftSideMenuCollapsedChange?: (collapsed: boolean) => void,
};
export type OrgApplicationBarProps = ApplicationHeaderProps & {
    navigation?: ApplicationBarNavigation,
    actions?: ActionMenuProps,
    contentClassName?: string,
    children?: React.ReactNode,
    leftSideMenuCollapsed?: boolean,
    onLeftSideMenuCollapsedChange?: (collapsed: boolean) => void,
};

type ApplicationBarProps = OrgApplicationBarProps | GlobalApplicationBarProps;
export const ApplicationBar = forwardRef(({
    navigation,
    actions,
    children,
    contentClassName,
    title,
    routerLinks,
    leftSideMenuCollapsed,
    onLeftSideMenuCollapsedChange,
}: ApplicationBarProps, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {

    const internalRef = useRef<HTMLDivElement | null>(null);
    const appBarContentRef: RefObject<HTMLDivElement> = forwardedRef as MutableRefObject<HTMLDivElement> || internalRef;
    const orgCookie = useOrgCookie();

    const isMenuType = navigation?.type === "Menu";
    const sideMenuContent = isMenuType && navigation ? (
        <LeftSideMenu
            id="left-side-menu"
            items={navigation.items}
            selectedItemId={navigation.selectedItemId}
            onItemSelected={navigation.onItemSelected}
            collapsed={leftSideMenuCollapsed}
            onCollapsedChange={onLeftSideMenuCollapsedChange}
        >
            {children}
        </LeftSideMenu>
    ) : null;

    const applicationBarClasses = classNames(
        theme.applicationBar,
        navigation?.type === "Steps" && !actions && theme.stepsOnly
    );
    const layoutContentClasses = classNames(theme.content, contentClassName);

    const content = sideMenuContent ?? children;
    const containerClasses = classNames(
        theme.applicationBarContainer,
        content && theme.applicationBarContainerWithContent
    );
    return (
        <Layout className={containerClasses}>
            <Layout.Header className={theme.applicationHeaderContainer}>
                {orgCookie ?
                    <ApplicationHeader
                        title={title}
                        routerLinks={routerLinks}
                    /> :
                    <GlobalApplicationHeader
                        title={title}
                        routerLinks={routerLinks}
                    />
                }
                <div className={applicationBarClasses}>

                    {actions && <div className={theme.actions}>
                        <ActionMenu
                            id={actions.id}
                            actions={actions.actions}
                        />
                    </div>}

                    {navigation && !isMenuType && <div className={theme.tabsOrSteps}>
                        <Navigation appBarContentRef={appBarContentRef} data={navigation} />
                    </div>}

                </div>

            </Layout.Header>

            {content && <Layout.Content ref={appBarContentRef} className={layoutContentClasses}>{content}</Layout.Content>}
        </Layout>
    );
});
