import React from "react";

import { Popover } from "antd";

import { assertNever } from "../../../utils/obj";

import theme from "./theme.module.scss";

export type TextToolState =
    { type: "ENABLED"}
    | { type: "DISABLED", reason?: string };

interface TextToolProps {
    state: TextToolState,
    onClick: () => void,
    children: React.ReactNode,
}

export function TextTool({ state, children, onClick }: TextToolProps) {
    let classname = "";
    let content = children;
    switch (state.type) {
        case "ENABLED":
            classname = theme.textTool;
            break;
        case "DISABLED":
            classname = theme.disabledTextTool;
            if (state.reason !== undefined) {
                content = <Popover content={state.reason}>
                    {children}
                </Popover>;
            }
            break;
        default:
            return assertNever(state);
    }
    return <span
        className={classname}
        onClick={state.type === "ENABLED" ? onClick : undefined}
    >
        {content}
    </span>;
}
