import React from "react";

import { AnimatePresence, motion } from "motion/react";

import theme from "./theme.module.scss";

export const TRANSITION_DURATION = 0.3;

interface CollapseTransitionProps {
    collapsed?: boolean,
    children: React.ReactNode,
}
export function CollapseTransition({
    collapsed,
    children,
}: CollapseTransitionProps) {
    return (
        <AnimatePresence initial={false}>
            {!collapsed && (
                <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: TRANSITION_DURATION }}
                >
                    <div className={theme.collapsibleContent}>
                        {children}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
