import React from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import Button from "antd/es/button";

export interface AddFilterButtonProps {
    addLabel?: string,
    items: MenuProps["items"],
}
export function AddFilterButton({
    addLabel,
    items,
}: AddFilterButtonProps) {


    return (
        <Dropdown
            menu={{ items }}
            trigger={["click"]}
        >
            <Button
                type="primary"
            >
                <PlusOutlined />
                {addLabel}
            </Button>
        </Dropdown>
    );
}
