import React from "react";

import classNames from "classnames";

import theme from "./theme.module.scss";

export interface CellProps {
    children: React.ReactNode,
    sorted?: boolean,
    width?: number,
}
export function Cell({
    children,
    sorted,
    width,
}: CellProps) {
    return (
        <td className={classNames(theme.cell, sorted && theme.cellSorted)} style={{ width: width ?? "auto" }} >
            {children}
        </td>
    );
}
