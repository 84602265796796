import React, { useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

interface DropdownToolProps {
    children: React.ReactNode,
    items?: { key: React.Key, label: React.ReactNode, disabled: boolean }[],
}

export function DropdownTool({
    children,
    items,
}: DropdownToolProps) {
    const [opened, setOpened] = useState(false);
    return (<Dropdown
        menu={{ items }}
        open={opened}
        onOpenChange={setOpened}
        trigger={["click"]}
        placement="bottomRight"
    >
        <a
            className="ant-dropdown-link"
            onClick={e => { e.preventDefault(); }}
        >
            <Space>
                {children}
                <DownOutlined />
            </Space>
        </a>
    </Dropdown>);
}
