import React from "react";

import { Column, flexRender, Header, Table } from "@tanstack/react-table";
import { MenuProps } from "antd";

import { FilterBar } from "../../filterBar";

import { TSTFilterBarRow } from "./tstFilterBarRow";
import { useTSTFilterUtils } from "./useTSTFilterUtils";


export interface TSTFilterBarProps<T> {
    table: Table<T>,
    addLabel: string,
    globalSearchLabel: string,
    hideFiltersLabel: string,
    getFilterCountLabel: (filterCount: number) => string,
    getFilterComponent: (column: Column<T>) => React.ReactNode,
}
export function TSTFilterBar<T>({
    table,
    addLabel,
    globalSearchLabel,
    getFilterCountLabel,
    hideFiltersLabel,
    getFilterComponent,
}: TSTFilterBarProps<T>) {
    const {
        selectedFilterColumns,
        filterableColumns,
        setSelectedFilterColumnIds,
        selectedFilterColumnIds,
    } = useTSTFilterUtils<T>(table);

    const handleAddFilter = (columnId: string | null) => {
        if (!columnId) {
            return;
        }
        setSelectedFilterColumnIds([...selectedFilterColumnIds, columnId]);
    };
    const addFilterItems: MenuProps["items"] = filterableColumns
        .filter(col => !selectedFilterColumnIds.includes(col.id))
        .map(col => ({
            label: flexRender(col.columnDef.header, { table, column: col, header: { column: col } as Header<unknown, unknown> }),
            key: col.id,
            onClick: () => handleAddFilter(col.id),
        }));

    const filterCount = selectedFilterColumnIds.length;

    return (
        <FilterBar
            addLabel={addLabel}
            globalSearchLabel={globalSearchLabel}
            hideFiltersLabel={hideFiltersLabel}
            getFilterCountLabel={getFilterCountLabel}
            addFilterItems={addFilterItems}
            filterCount={filterCount}
            onGlobalSearchChange={value => table.setGlobalFilter(value)}
        >
            {selectedFilterColumns.map((col, index) => {
                return (
                    <TSTFilterBarRow
                        key={col.id}
                        column={col}
                        table={table}
                        addLabel={addLabel}
                        isLastFilter={index === selectedFilterColumns.length - 1}
                    >
                        {getFilterComponent(col)}
                    </TSTFilterBarRow>
                );
            }
            )}
        </FilterBar>
    );
}
