import React from "react";

import { Empty } from "antd";

interface NoResultsProps {
    description?: React.ReactNode,
}

/**
 * NoResults component displays a message when there are no results to display.
 *
 * We may want to decouple this component from antd at some point.
 */
export function NoResults({
    description,
}: NoResultsProps) {
    return (
        <Empty description={description} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    );
}
