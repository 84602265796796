import React from "react";

import { Header, RowData, Table, flexRender } from "@tanstack/react-table";
import { Checkbox, Space } from "antd";

import { DropdownTool } from "../../table/toolbar/dropdownTool";

interface TSTAdjustViewProps<T extends RowData> {
    table: Table<T>,
    children: React.ReactNode,
}

export function TSTAdjustView<T extends RowData>({
    table,
    children,
}: TSTAdjustViewProps<T>) {
    const items = table
        .getAllColumns()
        // Only display accessor columns, to filter out columns such as :
        // * Selection
        // * Actions
        .filter(column => column.accessorFn !== undefined)
        .map(column => {
            // Unfortunate hack to render the column's header
            // See https://github.com/TanStack/table/discussions/4857
            const headerRendered = flexRender(column.columnDef.header, { table, column, header: { column } as Header<T, unknown> });

            const disabled = !column.getCanHide();
            const label = <Space onClick={column.getToggleVisibilityHandler()}>
                <Checkbox
                    checked={column.getIsVisible()}
                    disabled={disabled}
                />
                {headerRendered}
            </Space>;

            return {
                key: column.id,
                label,
                disabled,
            };
        });
    return (
        <DropdownTool items={items}>{children}</DropdownTool>
    );
}
