import React from "react";

import { flexRender, Row as ReactTableRow, RowData } from "@tanstack/react-table";

import { Cell } from "../../table/cell";
import { Row } from "../../table/row";


interface TSTRowProps<T> {
    row: ReactTableRow<T>,
}
export function TSTRow<T extends RowData>({
    row,
}: TSTRowProps<T>) {
    return (
        <Row selected={row.getIsSelected()}>
            {row.getVisibleCells().map(cell => {
                return (
                    <Cell key={cell.id} sorted={cell.column.getIsSorted() !== false} width={cell.column.getSize()}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Cell>
                );
            })}
        </Row>
    );
}
