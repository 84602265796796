import { Column, Table } from "@tanstack/react-table";
import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";

const filterColumnIdsAtomFamily = atomFamily((_key: string) => atom<string[]>([]));

// Utils for our filtering UI
// - Filter addition and removal / visibility
export function useTSTFilterUtils<T>(
    table: Table<T>,
) {
    const filterableColumns = table.getAllColumns().filter(column =>
        column.getCanFilter()
    );
    const filterableColumnIds = filterableColumns.map(column => column.id);

    const tableId = table.options.meta?.id;
    if (!tableId) {
        throw new Error("Table ID is required. Declare it in meta.id for useReactTable");
    }
    const [selectedFilterColumnIds, setSelectedFilterColumnIds] = useAtom(filterColumnIdsAtomFamily(tableId));
    const handleFilterRemove = (column: Column<T>) => {
        setSelectedFilterColumnIds(selectedFilterColumnIds => selectedFilterColumnIds.filter(colId => colId !== column.id));
        column.setFilterValue(null);
    };

    const filterColumns = table.getAllColumns().filter(col =>
        col.getCanFilter() &&
        filterableColumns.some(availableFilter => availableFilter.id === col.id)
    );
    const selectedFilterColumns = filterColumns.filter(filterHeader =>
        selectedFilterColumnIds.some(colId => colId === filterHeader.id)
    ).sort((a, b) => selectedFilterColumnIds.indexOf(a.id) - selectedFilterColumnIds.indexOf(b.id));

    const handleFilterChange = (value: string, column: Column<T>) => {
        const newSelectedColumns = selectedFilterColumnIds.map(colId => {
            if (colId === column.id) {
                column.setFilterValue(null);
                return value;
            } else {
                return colId;
            }
        });
        setSelectedFilterColumnIds(newSelectedColumns);
    };
    const handleFilterAdd = (newColumnId: string) => {
        const columnToAdd = filterableColumns.find(filter => filter.id === newColumnId);
        if (!columnToAdd) {
            throw new Error(`Filter with id ${newColumnId} not found`);
        }
        const columnIdToAdd = columnToAdd.id;
        setSelectedFilterColumnIds(selectedFilterColumnIds => [...selectedFilterColumnIds, columnIdToAdd]);
    };

    return {
        filterableColumns,
        filterableColumnIds,
        selectedFilterColumnIds,
        setSelectedFilterColumnIds,
        selectedFilterColumns,
        filterColumns,
        handleFilterChange,
        handleFilterAdd,
        handleFilterRemove,
    };
}
