import React from "react";

import { RowData, Table } from "@tanstack/react-table";

import { EmptyTableBox } from "../../table/emptyTableBox";
import { NoResults } from "../../table/noResults";

import { TSTRow } from "./tstRow";


interface TSTRowsProps<T extends RowData> {
    table: Table<T>,
    noSourceRowsFallback: React.ReactNode,
    noResultsFallback: React.ReactNode,
}
export function TSTRows<T extends RowData>({
    table,
    noSourceRowsFallback,
    noResultsFallback,
}: TSTRowsProps<T>) {
    const rowComponents = table.getRowModel().rows.map(row => {
        return (
            <TSTRow
                key={row.id}
                row={row}
            />
        );
    });
    const hasSourceRows = table.options.data.length > 0;
    if (rowComponents.length === 0) {
        return (
            <EmptyTableBox>
                <NoResults description={hasSourceRows ? noResultsFallback : noSourceRowsFallback} />
            </EmptyTableBox>
        );
    }
    return (
        <tbody>
            {rowComponents}
        </tbody>
    );
}
