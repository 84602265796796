import React from "react";

import selectedActions from "../../../img/selected-actions-arrow.svg";
import { Svg } from "../../../utils/svgIcon";

import { DropdownTool } from "./dropdownTool";
import { TextTool } from "./textTool";
import theme from "./theme.module.scss";
import { ToolbarSpacer } from "./toolbarSpacer";

interface TableToolbarProps {
    children?: React.ReactNode,
}

export function TableToolbar({
    children,
}: TableToolbarProps) {
    return (
        <div className={theme.reactableToolbarContainer}>
            <div className={theme.reactableToolbar}>
                <Svg className={theme.imageDivider} src={selectedActions} />
                {children}
            </div>
        </div>
    );
}

export {
    DropdownTool,
    ToolbarSpacer,
    TextTool,
};
