import React, { useMemo, useRef, useState } from "react";

import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, MenuProps, Tag } from "antd";
import { AnimatePresence, motion } from "motion/react";

import { CollapseTransition } from "../collapsibleHeight/collapsibleHeight";

import { AddFilterButton } from "./addFilterButton";
import theme from "./theme.module.scss";

const DEBOUNCE_TIME = 350;

export interface FilterBarProps {
    children: React.ReactNode,
    addLabel: string,
    globalSearchLabel: string,
    hideFiltersLabel: string,
    getFilterCountLabel: (filterCount: number) => string,
    addFilterItems: MenuProps["items"],
    filterCount: number,
    onGlobalSearchChange: (value: string) => void,
}
export function FilterBar({
    children,
    addLabel,
    globalSearchLabel,
    getFilterCountLabel,
    hideFiltersLabel,
    filterCount,
    addFilterItems,
    onGlobalSearchChange,
}: FilterBarProps) {
    const [isHidden, setIsHidden] = useState(false);

    const hasFilters = filterCount > 0;
    const debounceTimeout = useRef<ReturnType<typeof setTimeout>>();

    const handleSearch = useMemo(() => {
        return (value: string) => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }

            debounceTimeout.current = setTimeout(() => {
                onGlobalSearchChange(value);
            }, DEBOUNCE_TIME);
        };
    }, [onGlobalSearchChange]);

    return (
        <div className={theme.root}>
            <div className={theme.topRow}>
                {!hasFilters && (
                    <AddFilterButton
                        addLabel={addLabel}
                        items={addFilterItems}
                    />
                )}
                {hasFilters && (
                    <div className={theme.collapseContainer}>
                        <Button
                            type="link"
                            onClick={() => setIsHidden(!isHidden)}
                        >
                            <div className={theme.collapseButtonContent}>
                                <motion.div
                                    animate={{ rotate: isHidden ? 0 : 180 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    <DownOutlined />
                                </motion.div>
                                {hideFiltersLabel}
                            </div>
                        </Button>
                        {isHidden && (
                            <Tag color="processing">
                                {getFilterCountLabel(filterCount)}
                            </Tag>
                        )}
                    </div>
                )}

                <div className={theme.addonAfter}>
                    <Input
                        onChange={e => handleSearch(e.target.value)}
                        placeholder={globalSearchLabel}
                        addonAfter={<SearchOutlined />}
                        name="table-global-search"
                        allowClear
                    />
                </div>
            </div>

            <CollapseTransition collapsed={isHidden}>
                <AnimatePresence>
                    {children}
                </AnimatePresence>
            </CollapseTransition>
        </div>
    );
}
