import React from "react";

import { InputNumber } from "antd";

import theme from "./theme.module.scss";

export interface FilterBarRangeProps {
    value: [number, number],
    min: number,
    max: number,
    minPlaceholderLabel: string,
    maxPlaceholderLabel: string,
    onChange: (updater: (prev: [number | null, number | null]) => [number | null, number | null]) => void,
}
export function FilterBarRange({
    value,
    min,
    max,
    minPlaceholderLabel,
    maxPlaceholderLabel,
    onChange,
}: FilterBarRangeProps) {
    return (
        <div>
            <div className={theme.rangeInputs}>
                <div className={theme.rangeWrapper}>
                    <InputNumber
                        value={value?.[0]}
                        onChange={v => onChange(prev => [v, prev?.[1] ?? null])}
                        min={min}
                        max={max}
                        placeholder={`${minPlaceholderLabel}: ${min}`}
                        autoFocus
                    />
                </div>

                <div className={theme.rangeWrapper}>
                    <InputNumber
                        value={value?.[1]}
                        onChange={v => onChange(prev => [prev?.[0] ?? null, v])}
                        min={min}
                        max={max}
                        placeholder={`${maxPlaceholderLabel}: ${max}`}
                    />
                </div>
            </div>
        </div>
    );
}
