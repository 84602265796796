import React from "react";

import { flexRender, Table, Column, Header } from "@tanstack/react-table";
import { MenuProps } from "antd";

import { FilterBarRow } from "../../filterBar";

import { useTSTFilterUtils } from "./useTSTFilterUtils";

interface TSTFilterBarRowProps<T> {
    column: Column<T>,
    isLastFilter: boolean,
    children: React.ReactNode,
    table: Table<any>,
    addLabel?: string,
}

export function TSTFilterBarRow<T>({
    column,
    children,
    isLastFilter,
    addLabel,
    table,
}: TSTFilterBarRowProps<T>) {
    const {
        handleFilterChange,
        handleFilterRemove,
        filterableColumns,
        filterColumns,
        selectedFilterColumnIds,
        handleFilterAdd,
    } = useTSTFilterUtils(table);

    const columnOptions = filterColumns.map(col => ({
        label: flexRender(col.columnDef.header, { table, column: col, header: { column: col } as Header<T, unknown> }),
        value: col.id,
    }));

    const addFilterItems: MenuProps["items"] = filterableColumns
        .filter(col => !selectedFilterColumnIds.includes(col.id))
        .map(col => ({
            label: flexRender(col.columnDef.header, { table, column: col, header: { column: col } as Header<unknown, unknown> }),
            key: col.id,
            onClick: () => handleFilterAdd(col.id),
        }));

    return (
        <FilterBarRow
            addFilterItems={addFilterItems}
            columnOptions={columnOptions}
            isLastFilter={isLastFilter}
            onColumnOptionChange={value => handleFilterChange(value, column)}
            onFilterRemoveClick={() => handleFilterRemove(column)}
            selectedColumnOption={column.id}
            addLabel={addLabel}
        >
            {children}
        </FilterBarRow>

    );
}
